<template>
  <div class="leet-carbon-page">
    <div class="page-header">
      <img src="https://cdn.lnxall.com/banner3.png" alt="" srcset="" />
      <div class="title-box">
        <div class="title-content">
          <div class="top-title animate__animated animate__backInLeft">
            零碳能源大脑
          </div>
          <div
            class="info-des animate__animated animate__backInLeft animate__delay-1s"
          >
            充分发挥简捷物联的核心技术优势 <br />有效打通各类<span
              >双碳基础设施</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- one -->
    <div
      class="page-full-body-content"
      style="background: #ffffff; padding-bottom: 10px"
    >
      <div class="full-page-content">
        <div class="title-box">
          通过绿色能源、绿色建筑、绿色交通、绿色产业等多全碳场景的接入，
          成功构建双碳生态系统，帮助客户轻松实现形成“5个1”——
        </div>
        <div class="data-box" style="margin-top:20px;">
          <div class="data-item">
            <img src="https://cdn.lnxall.com/01.png" />
            <div class="intro">双碳基础<br />设施一张网</div>
          </div>
          <div class="data-item">
            <img src="https://cdn.lnxall.com/02.png" />
            <div class="intro">双碳态势<br />感知一张图</div>
          </div>
          <div class="data-item">
            <img src="https://cdn.lnxall.com/03.png" />
            <div class="intro">双碳资源<br />监控一盘棋</div>
          </div>
        </div>
        <div class="data-box" style="margin-top: 10px; padding: 0 10px">
          <div class="data-item">
            <img src="https://cdn.lnxall.com/04.png" />
            <div class="intro">双碳风险<br />管控一条线</div>
          </div>
          <div class="data-item">
            <img src="https://cdn.lnxall.com/05.png" />
            <div class="intro">双碳产业<br />服务一站通</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="background: #f8fbff; position: relative"
    >
      <titleBar title="架构设计" style="margin-top: 20px"></titleBar>
      <div class="sub-title-box">零碳能源大脑</div>
      <div class="page-top-content">
        <img src="https://cdn.lnxall.com/mobile/top.png" alt="" />
      </div>
      <div class="page-bottom-content">
        <img src="https://cdn.lnxall.com/mobile/bottom.png" alt="" />
      </div>
    </div>
    <div class="page-full-body-content" style="position: relative">
      <div class="page-full-one page-one">
        <titleBar title="三大核心模块" style="margin-top: 20px"></titleBar>
        <div class="page-body-content">
          <div class="leet-content-intro" style="margin-top: 20px">
            <div class="image-content">
              <img src="https://cdn.lnxall.com/lee01.png" alt="" />
            </div>
            <div class="info-content">
              <div class="title-info">
                物联网边缘计算模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon leet1">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">节能控碳策略及碳排放预警，有效控碳管理</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-one page-one" style="background: #f8fbff">
        <div class="page-body-content">
          <div class="leet-content-intro">
            <div class="info-content" style="margin-top: 14px; padding: 0 20px">
              <div class="title-info" style="width: 230px">
                能源管理模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon leet2">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">可视化地图实时监测碳排放以及碳资产情况</div>
            </div>
            <div class="image-content">
              <img src="https://cdn.lnxall.com/lee02.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-one page-one" style="background: #ffffff">
        <div class="page-body-content">
          <div class="leet-content-intro" style="margin-top: 30px;margin-bottom: 40px;">
            <div class="image-content">
              <img src="https://cdn.lnxall.com/lee03.png" alt="" />
            </div>
            <div class="info-content" style="margin-top: 20px;">
              <div class="title-info">
                双碳管理模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon leet3">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">实时掌握碳中和目标及路径，控碳有的放矢</div>
            </div>
          </div>
          <div class="card-content" style="margin-top: 10px">
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon01.png" />
              <div class="sub-intro">信息可视化</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon02.png" />
              <div class="sub-intro">多维报表</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon03.png" />
              <div class="sub-intro">耗能监测</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon04.png" />
              <div class="sub-intro">用能动态</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon05.png" />
              <div class="sub-intro">隔墙用能交易</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon06.png" />
              <div class="sub-intro">区域节能策略</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon07.png" />
              <div class="sub-intro">碳排放监测</div>
            </div>
            <div class="card-list">
              <img src="https://cdn.lnxall.com/icon08.png" />
              <div class="sub-intro">碳汇交易</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-full-body-content" style="background-color: #ffffff">
      <div class="selection-part-two">
        <titleBar title="功能特点" style="margin-top: 10px"></titleBar>
        <div
          class="section-content"
          @mouseenter="cardactive = false"
          @mouseleave="cardactive = true"
        >
          <div class="item-card-list">
            <div class="card-info">
              可视化地图实时监测碳排放以及碳资产情况
            </div>
          </div>
          <div class="item-card-list">
            <div class="card-info">节能控碳策略及碳排放预警,有效控碳管理</div>
          </div>
          <div class="item-card-list">
            <div class="card-info">分户分项碳排放统计,精细化碳管理及交易</div>
          </div>
          <div class="item-card-list">
            <div class="card-info">实时掌握碳中和目标及路径,控碳有的放矢</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="
        margin-top: 0;
        background: url('https://cdn.lnxall.com/img17.png') no-repeat;
        background-size: 100% 100%;
      "
    >
      <div class="leet-full-content">
        <titleBar title="用户价值" style="margin-top: 10px"></titleBar>
        <div class="selection-part-two">
          <div class="plane-content">
            <div class="plane-item">
              <div class="imagebox">
                <img src="https://cdn.lnxall.com/icon09.png" alt="" srcset="" />
              </div>
              <div class="des">设备节能<br />精细化管理</div>
            </div>
            <div class="plane-item">
              <div class="imagebox">
                <img src="https://cdn.lnxall.com/icon10.png" alt="" srcset="" />
              </div>
              <div class="des">企业能源<br />信息化集中管理</div>
            </div>
            <div class="plane-item">
              <div class="imagebox">
                <img src="https://cdn.lnxall.com/icon11.png" alt="" srcset="" />
              </div>
              <div class="des">整体能源<br />系统化智慧管理</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
export default {
  name: "carbon-page",
  components: {
    titleBar,
  },
  data() {
    return {
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-carbon-page {
  .page-header {
    height: 260px;
    background-color: #000106;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title-box {
      position: absolute;
      left: 20px;
      right: 0;
      top: 60px;
      box-sizing: border-box;
      .title-content {
        margin: 0 auto;
        .top-title {
          height: 70px;
          color: #ffffff;
          font-size: 22px;
          font-family: PingFangSC-Medium;
          line-height: 70px;
        }
        .info-des {
          margin-top: 10px;
          font-size: 20px;
          font-family: PingFangSC-Light;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 1.2px;
          span {
            font-size: 22px;
            color: #ffffff;
            font-family: PingFangSC-Medium;
          }
        }
      }
    }
  }
  .page-full-body-content {
    border: 1px solid transparent;
    box-sizing: border-box;
    .title-content {
      text-align: center;
      height: 42px;
      color: #222222;
      font-size: 22px;
      letter-spacing: 2px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      margin-top: 10px;
    }
    .plane-content {
      display: flex;
      justify-content: space-around;
      .plane-item {
        width: 33.3%;
        .imagebox {
          width: 100%;
          text-align: center;
          margin-top: 20px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .des {
          margin-top: 20px;
          text-align: center;
          color: #222222;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          margin-bottom: 20px;
        }
      }
    }
    .selection-part-two {
      margin: 0 auto;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid transparent;
      .section-title {
        width: 100%;
        text-align: center;
        color: #222222;
        font-size: 30px;
        letter-spacing: 2px;
        font-family: PingFangSC-Medium;
        line-height: 42px;
        box-sizing: border-box;
        margin-top: 76px;
      }
      .section-content {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: space-around;
        .item-list {
          height: 202px;
          border-radius: 8px;
          border: 1px solid #f5f5f5;
          box-shadow: 0px 8px 20px 0px rgb(213, 213, 227, 20%);
          width: 25%;
          margin-bottom: 38px;
          position: relative;
          .iconbox {
            position: absolute;
            top: 39px;
            left: 55px;
            width: 48px;
            height: 48px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          .titlebox {
            position: absolute;
            top: 10px;
            left: 11px;
            height: 30px;
            color: #222222;
            font-size: 14px;
            font-family: PingFangSC-Medium;
            text-align: left;
          }
          .info {
            position: absolute;
            top: 78px;
            left: 145px;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 14px;
            line-height: 36px;
            text-align: left;
            margin-top: 9px;
            width: 400px;
          }
        }
        // three
        .item-card-list {
          width: 46%;
          height: 100px;
          box-sizing: border-box;
          position: relative;
          box-shadow: 4px 0px 84px 0px rgba(183, 188, 205, 0.4);
          margin-bottom: 20px;
          .card-title {
            position: absolute;
            top: 82px;
            left: 30px;
            height: 30px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            text-align: left;
          }
          .card-info {
            position: absolute;
            top: 25px;
            left: 20px;
            right: 20px;
            font-size: 14px;
            text-align: left;
          }
        }
        .actvie-card {
          // background-image: url("@/assets/basis05.gif") !important;
          color: #fff !important;
        }
        .item-card-list:hover {
          // background-image: url("@/assets/basis05.gif") !important;
          color: #fff !important;
        }
      }
    }
    .full-page-content {
      margin: 20px auto;
      .title-box {
        margin-top: 10px;
        overflow-wrap: break-word;
        color: #222222;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 28px;
        text-align: center;
        padding: 0 10px;
      }
      .data-box {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        .data-item {
          position: relative;
          width: 116px;
          height: 50px;
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgba(183, 188, 205, 0.3);
          overflow: hidden;
          background-color: #fff;
          position: relative;
          img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 42px;
            height: 26px;
          }
          .intro {
            position: absolute;
            right: 10px;
            top: 10px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 12px;
            font-family: PingFangSC-Medium;
            text-align: center;
          }
        }
      }
    }
    .sub-title-box {
      width: 100%;
      height: 42px;
      color: #222222;
      font-size: 12px;
      letter-spacing: 2px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 42px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 60px;
    }
    .page-top-content {
      margin-top: 20px;
      width: 100%;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
    .page-bottom-content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      img {
        width: 100%;
      }
    }
    .page-full-one {
      border: 1px solid transparent;
      background-color: #fff;
      box-sizing: border-box;
    }
    .page-one{
      padding: 20px 0;
    }
    .page-body-content {
      width: 100%;
      .card-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        .card-list {
          height: 80px;
          border-radius: 8px;
          box-shadow: 0px -8px 20px 0px rgb(213, 223, 227, 20%);
          margin-bottom: 10px;
          position: relative;
          width: 46%;
          img {
            top: 10px;
            left: 10px;
            position: absolute;
            width: 40px;
          }
          .sub-intro {
            display: inline-block;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 16px;
            white-space: nowrap;
            line-height: 30px;
            text-align: left;
            margin-top: 13px;
            position: absolute;
            top: 14px;
            left: 60px;
          }
        }
      }
    }
  }
  .leet-content-intro {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .image-content {
      padding: 10px;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .info-content {
      width: 60%;
      display: inline-block;
      .title-info {
        position: relative;
        height: 40px;
        color: #222222;
        font-size: 14px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 40px;
        text-align: left;
        padding: 0 20px;
        display: flex;
        .left-top-icon {
          width: 16px;
          height: 16px;
          position: absolute;
          top: -4px;
          left: 0px;
          z-index: 2;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .right-top-icon {
          width: 14px;
          height: 14px;
          position: absolute;
          bottom: 14px;
          right: 60px;
          z-index: 2;
          img {
            width: 14px;
            height: 14px;
          }
        }
        .leet1{
           right: 48px !important;
           bottom: 18px !important;
        }
        .leet2{
           left: 110px !important;
           bottom: 18px !important;
        }
        .leet3{
           left: 110px !important;
           bottom: 18px !important;
        }
      }
      .intro {
        padding: 0px 10px;
        overflow-wrap: break-word;
        color: #555555;
        font-size: 12px;
        text-align: left;
      }
    }
  }
}
</style>
